
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '客服信箱管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '客服信箱管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '客服信箱管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [{
        field: 'Name',
        title: '名稱',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      {
        field: 'Status',
        title: '狀態',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => {
          const item = model?.enums && Object.values(model?.enums.SuggestionStatus).find((e) => e.Value === cellValue);
          return item ? item.Name : "";
        }
      },
      { field: 'Email', title: '聯絡信箱', showHeaderOverflow: true, showOverflow: true, sortable: true },
      { field: 'AgreedTerms', title: '同意使用條款', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center', formatter: ({ cellValue }) => cellValue ? '是' : '否' },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('suggestion/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('suggestion/query') : undefined,
        save: model ? (params) => model.dispatch('suggestion/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'MemberId',
          title: '會員名稱',
          span: 12,
          slots: { default: "column-member-id" }
        },
        {
          field: 'Name',
          title: '名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'CompanyName',
          title: '名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Email',
          title: '聯絡信箱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Phone',
          title: '聯絡電話',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Status',
          title: '處理狀態',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.SuggestionStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'AgreedTerms',
          title: '同意使用條款',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'OperatorId',
          title: '操作人員',
          span: 12,
          slots: { default: "column-user-id" }
        },
        {
          field: 'ConsultCategory',
          title: '諮詢類別',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        { field: 'Content', title: '意見內容', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '', disabled: 'true' } } },
      ],
      rules: {
        Name: [{ type: 'string', required: true }],
        Type: [{ required: true }],
      }
    }

    const initData = (row: any, callback: any) => {
      row.Published = false;
      row.Type = model?.enums?.suggestionType ? Object.values(model.enums.BookType)[0].Value : 0;
      row.Ordinal = 0;
      callback();
    }

    const memberSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      disabled: false,
      columns: [
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("member/find", value), // eslint-disable-line
        query: params => model!.dispatch("member/query", params) // eslint-disable-line
      }
    };

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => model!.dispatch("user/query", params) // eslint-disable-line
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      initData,
      memberSelectorOptions,
      userSelectorOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
